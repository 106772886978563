.body {
    text-align: center;
    min-width: 100%;
    height: 100vh;
    background-color: rgb(255, 160, 129);
}

.body h1 {
    font-size: 40px ;
    padding-bottom: 2px;
    height: auto;
    text-align: center;
    color: white;
    font-weight: 300;
}

.body h2 {
    font-size: 25px ;
    height: auto;
    padding-bottom: 30px;
    text-align: center;
    color: white;
    font-weight: 300;
    border: 0;
}

.paragraphs {
    text-align: center;
    display: flex;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;

}

.body p {
    font-size: 20px;
    text-align: center;
    color: white;
    line-height: 200%;
    font-weight: 300;
    border: 0;
}

.paragraphs p {
    font-size: 35px;
    line-height: 1.3;
    font-weight: 500;
    padding-bottom: 20px;
}