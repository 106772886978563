* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0px;
}
.form-box {
    text-align: center;
    min-width: 100%;
    height: auto;
    margin: 0vw;
    padding: 5vw;
    position: relative;
    background-color: rgb(255, 160, 129);
}

.form-box h5{
    font-size: 35px ;
    text-align: center;
    color: white;
    margin: 0 0 3vh 0;
    font-weight: 300;
    border: 0;
}

.form-box input, textarea{
    display: block;
    width: 80%;
    padding: .5rem .8rem .5rem .8rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    border: 1;
    border-radius: 5px;
    font-size: 20px;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.form-box textarea{
    height: 15vh;
}