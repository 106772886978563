* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0px;
}
.header {
    text-align: center;
    min-width: 100%;
    height: 100vh;
    margin: 0vw;
    padding: 5vw;
    position: relative;
    background-color: rgb(255, 160, 129);
}

.header h1 {
    font-size: 50px ;
    text-align: center;
    color: white;
    margin: 0 0 3vh 0;
    font-weight: 300;
    border: 0;
}

.header h2 {
    font-size: 30px ;
    text-align: center;
    color: white;
    margin: 0 0 3vh 0;
    font-weight: 300;
    border: 0;
}